@import '../../../../styles/variables';

.Clock {
  margin: auto;
  display: flex;
  margin-top: 16px;

  h3 {
    width: fit-content;
    margin: auto;
    color: #646464;
    font-size: $font-small;
    line-height: 1;
    padding-bottom: 6px;
  }
}
