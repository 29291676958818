// .submitBtn applies to all types of the button
.submitBtn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

// Custom styles for cancel/start button
.cancel,
.cancelOnly{
  background-color: #f44336;
  color: white;
}

.start{
  background-color: #4caf50;
  color: white;
}

.schedule {
  background-color: blue;
  color: white;
  width: 700px;
  margin-top: 20px;
  font-size: xx-large;
}

.start-admin {
  background-color: #f44336;
  color: white;
  width: fit-content;
}

.iconStartMission {
  font-size: x-large;
  margin-right: 8px;
}

.iconCancel,
.iconStart {
  margin-right: 8px;
  font-size: large;
}

.disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
