@import '../../../../styles/variables';

.Logout {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
  
  .logout-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    border-radius: 6px;
    color: #9a9a9a;
    transition: color 0.2s ease, background-color 0.2s ease;

    &:hover {
      color: #f05252; // Red color on hover to indicate logout action
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
} 