@import '../../../../styles/variables';

.Weather {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
  
  .weather-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    border-radius: 6px;
    color: #9a9a9a;
    transition: color 0.2s ease, background-color 0.2s ease;

    &:hover {
      color: darken(#9a9a9a, 10%);
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .weather-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Weather icons styling
  .weather-icon {
    &.sun {
      color: #ffdd00;
    }

    &.moon {
      color: #ffa500;
    }

    &.cloud {
      color: #d3d3d3;
    }

    &.cloud-drizzle, &.cloud-rain {
      color: #add8e6;
    }

    &.cloud-lightning {
      color: #ffa500;
    }

    &.snowflake {
      color: #add8e6;
    }

    &.fog {
      color: #d3d3d3;
    }
  }

  // Weather popup container
  .weather-popup {
    position: fixed;
    left: 70px;
    bottom: 120px;
    margin-bottom: 0;
    width: 280px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    animation: fadeIn 0.2s ease;

    &:before {
      content: '';
      position: absolute;
      left: -6px;
      bottom: 15px;
      width: 12px;
      height: 12px;
      background-color: white;
      transform: rotate(45deg);
      box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.05);
    }
  }

  // Tooltip content
  .weather-tooltip-content {
    padding: 16px;
    color: $black;

    // Header section
    .weather-tooltip-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      margin-bottom: 14px;

      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }

      .location {
        font-size: 13px;
        color: #666;
      }

      .error-icon {
        color: #f56565;
      }
    }

    // Loading state
    &.loading .loading-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px 0;
      
      .weather-loader {
        animation: spin 1s linear infinite;
        color: #4299e1;
        margin-bottom: 12px;
      }
      
      p {
        color: #666;
        font-size: 14px;
      }
    }

    // Error state
    &.error {
      p {
        color: #666;
        text-align: center;
        margin: 12px 0;
      }
      
      .error-message {
        font-size: 13px;
        color: #f56565;
        margin-bottom: 4px;
      }

      .error-details {
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 8px;
        padding: 12px;
        margin: 12px 0;

        .error-hint {
          font-size: 12px;
          color: #666;
          margin-top: 8px;
          font-style: italic;
        }
      }
    }

    // Limited data state
    &.limited {
      .limited-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px 0 12px;
        text-align: center;
        
        .warning-icon {
          color: #F59E0B;
          margin-bottom: 12px;
        }
        
        p {
          color: #666;
          font-size: 14px;
          margin: 4px 0;
        }

        .retry-hint {
          font-size: 12px;
          color: #666;
          max-width: 220px;
          margin-top: 8px;
          font-style: italic;
        }
      }
    }

    // Weather main information
    .weather-main-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;
      
      .weather-icon-large {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .weather-icon-container {
          transform: scale(1.5);
          margin-bottom: 6px;
        }
        
        .weather-description {
          font-size: 13px;
          color: #666;
          text-transform: capitalize;
        }
      }
      
      .temperature {
        display: flex;
        align-items: center;
        
        .temp-value {
          font-size: 24px;
          font-weight: 700;
          margin-left: 8px;
        }
      }
    }

    // Weather details
    .weather-details {
      background-color: rgba(0, 0, 0, 0.02);
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 12px;
      
      .weather-detail-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        
        &:last-child {
          margin-bottom: 0;
        }
        
        svg {
          margin-right: 8px;
          color: #666;
        }
        
        p {
          font-size: 13px;
          color: #666;
          
          span {
            font-weight: 600;
            color: #333;
          }
        }
      }
    }
    
    // Footer
    .weather-footer {
      display: flex;
      justify-content: flex-end;
      
      .weather-updated {
        font-size: 11px;
        color: #999;
      }
    }
    
    // Retry button
    .retry-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      margin: 12px auto 0;
      padding: 8px 16px;
      background-color: #f0f0f0;
      border: none;
      border-radius: 4px;
      color: #666;
      font-size: 13px;
      cursor: pointer;
      transition: background-color 0.2s ease, color 0.2s ease;
      
      svg {
        animation: spin-once 0.6s ease;
      }
      
      &:hover {
        background-color: #e5e5e5;
        color: #333;
      }
      
      &:active {
        background-color: #d9d9d9;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-8px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-once {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
