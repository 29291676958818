@import '../../../../styles/variables';

.NotificationsBell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
  
  .bell-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 28px;
    width: 28px;
    border-radius: 6px;
    color: #9a9a9a;
    transition: color 0.2s ease, background-color 0.2s ease;

    &:hover {
      color: darken(#9a9a9a, 10%);
      background-color: rgba(255, 255, 255, 0.05);
    }

    .notification-badge {
      position: absolute;
      top: -2px;
      right: -2px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 16px;
      height: 16px;
      padding: 0 4px;
      background-color: #f56565;
      color: white;
      font-size: 10px;
      font-weight: 600;
      border-radius: 10px;
    }
  }

  // Notifications popup container
  .notifications-popup {
    position: fixed;
    left: 70px;
    bottom: 50px; // Positioned lower than the weather popup to match icon position in sidebar
    margin-bottom: 0;
    width: 320px;
    max-height: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.2s ease;

    &:before {
      content: '';
      position: absolute;
      left: -6px;
      bottom: 15px;
      width: 12px;
      height: 12px;
      background-color: white;
      transform: rotate(45deg);
      box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.05);
    }

    // Header section with title and clear button
    .notifications-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        margin: 0;
      }

      .clear-all-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        color: #666;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
          color: #333;
        }
      }
    }

    // List of notifications
    .notifications-list {
      flex: 1;
      overflow-y: auto;
      max-height: 300px;
      padding: 0;

      // Empty state
      .empty-notifications {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        color: #666;
        font-size: 14px;
      }

      // Individual notification item
      .notification-item {
        display: flex;
        padding: 12px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.02);
        }

        .notification-content {
          flex: 1;
          min-width: 0; // Fix for text overflow

          .notification-title {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            gap: 8px;

            h4 {
              margin: 0;
              font-size: 14px;
              font-weight: 500;
              color: #333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .icon-check {
              color: #34D399; // Green
            }

            .icon-warning {
              color: #F59E0B; // Yellow/orange
            }

            .icon-error {
              color: #EF4444; // Red
            }
          }

          .notification-message {
            margin: 0;
            font-size: 12px;
            color: #666;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        .notification-close {
          display: flex;
          align-items: flex-start;
          padding: 2px;
          margin-left: 8px;
          color: #9CA3AF;
          border-radius: 4px;
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s ease, background-color 0.2s ease;

          &:hover {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-8px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
